import Client from 'shopify-buy';

let shopifyClient;

/**
 * Singleton instance of shopify client
 */
export const getShopifyClient = () => {
  if (!shopifyClient) {
    shopifyClient = Client.buildClient({
      domain: `${process.env.GATSBY_SHOPIFY_STORE_NAME}.myshopify.com`,
      storefrontAccessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
    });
  }
  return shopifyClient;
};
