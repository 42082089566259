import React from 'react';
import {
  IServersideBusiness,
  IYellowBusiness,
  Theme,
  ThemeVariant,
} from '@websites/model';
import { graphql, useStaticQuery } from 'gatsby';
import { ThemeContext } from '@websites/components';
import { isClient, serverBusinessToClientBusiness } from '@websites/utils';
import { parse } from 'query-string';

export interface IYellowBusinessStore {
  yellowBusiness?: IYellowBusiness;
  setYellowBusiness: (bus: IYellowBusiness) => void;
}

export const YellowBusinessContext = React.createContext<IYellowBusinessStore>({
  yellowBusiness: undefined,
  setYellowBusiness: () => console.error(
    'Default setYellowBusiness on YellowBusinessContext is being called. This should never happen.',
  ),
});

const query = graphql`
  query RootBusinessQuery {
    yellowBusiness {
      business {
        assets {
          caption
          contentUrl
          classification
          type
        }
        details {
          name
          description
          sameAs {
            name
            url
          }
          logo
        }
        providerConfiguration {
          # websites channel-specific details
          yellowWebsite {
            googleSiteVerificationId
            alertBar {
              link
              text
            }
            alternativeHeadline
            headline
            primaryImageOfPage
            gatsbyImagePrimaryImageOfPage {
              childImageSharp {
                fluid(maxWidth: 5120) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
            gallery
            galleryGatsbyList {
              galleryGatsbyImage{
                childImageSharp {
                  fluid(maxWidth: 5120) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
            slug
            customDomain
            faqs {
              question
              answer
            }
            greeting {
              image
              gatsbyImageImage {
                childImageSharp {
                  fluid(maxWidth: 768) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
              title
              body
              signatureImage
            }
          }
        }
        locations {
          address {
            administrativeArea
            locality
            postalCode
            streetName
            streetNumber
            sublocality
          }
          default
          email
          hasMap
          showOnWebsite
          geo {
            latitude
            longitude
          }
          id
          openingHours {
            opens
            closes
            dayOfWeek
            conditionsOfAccess
          }
          telephone {
            areaCode
            number
          }
        }
        products {
          description
          image
          name
        }
      }
    }
  }
`;

export const YellowBusinessProvider = ({ children }) => {
  // coming from iframe parent in live preview mode
  const [
    previewDataFromIFrameParent,
    setPreviewDataFromIFrameParent,
  ] = React.useState<IServersideBusiness>();

  const queryResult = useStaticQuery(query);
  const serverBusiness = queryResult.yellowBusiness.business;

  const clientBusiness: IYellowBusiness = serverBusinessToClientBusiness({
    serverBusiness,
    defaultTheme: ThemeVariant.spring,
  });

  const [yellowBusiness, setYellowBusiness] = React.useState<IYellowBusiness>(
    clientBusiness,
  );

  // eslint-disable-next-line no-restricted-globals
  const queryParams = isClient() ? parse(location.search) : null;

  /**
   * Reading theme from query params
   */
  React.useEffect(() => {
    if (
      yellowBusiness
      && queryParams?.theme
      && queryParams?.theme !== `${process.env.GATSBY_WEBSITES_THEME}`
    ) {
      const newBusiness = { ...yellowBusiness };
      newBusiness.website.theme = queryParams.theme as ThemeVariant;
      setYellowBusiness(newBusiness);
    }
  }, [queryParams]);

  /**
   * Reading data from iframe parent (for preview)
   */
  React.useEffect(() => {
    if (yellowBusiness && previewDataFromIFrameParent) {
      const iframeBusinessAsClientBusiness = serverBusinessToClientBusiness({
        serverBusiness: previewDataFromIFrameParent,
        defaultTheme: ThemeVariant.pro,
      });
      const newBusiness = {
        ...yellowBusiness,
        ...iframeBusinessAsClientBusiness,
      };
      setYellowBusiness(newBusiness);
    }
  }, [previewDataFromIFrameParent]);

  /**
   * On mount - register listener for potential postMessage calls
   */
  React.useEffect(() => {
    const eventHandler = (event) => {
      if (
        event.isTrusted
        && event.data?.details
      ) {
        setPreviewDataFromIFrameParent(event.data);
      }
    };
    window.addEventListener('message', eventHandler);
    return () => {
      window.removeEventListener('message', eventHandler);
    };
  }, []);

  const businessStore: IYellowBusinessStore = {
    yellowBusiness,
    setYellowBusiness,
  };

  const catalogSection = {
    name: 'Store',
    link: '/#catalog',
  };

  let aboutBusinessSection = null;
  if (yellowBusiness?.description) {
    aboutBusinessSection = {
      name: 'About',
      link: '/about',
    };
  }

  let gallerySection = null;
  if (yellowBusiness?.gallery?.length) {
    gallerySection = {
      name: 'Gallery',
      link: '/gallery',
    };
  }

  let faqSection = null;
  if (yellowBusiness?.website?.faqs) {
    faqSection = {
      name: 'FAQ',
      link: '/faq',
    };
  }

  let locationSection = null;
  const locationsToShowOnWebsite = yellowBusiness?.locations?.filter((location) => location.showOnWebsite);
  if (locationsToShowOnWebsite?.length) {
    const name = locationsToShowOnWebsite?.length > 1 ? 'Locations' : 'Find us';
    locationSection = {
      name,
      link: '/locations',
    };
  }

  const contactBusinessSection = {
    name: 'Contact',
    link: '/contact',
  };

  const sections = {
    catalogSection,
    aboutBusinessSection,
    gallerySection,
    faqSection,
    locationSection,
    contactBusinessSection,
  };

  return (
    <>
      {yellowBusiness && (
        <ThemeContext.Provider value={new Theme(yellowBusiness.website.theme)}>
          <YellowBusinessContext.Provider value={{ ...businessStore, sections }}>
            {children}
          </YellowBusinessContext.Provider>
        </ThemeContext.Provider>
      )}
    </>
  );
};
